import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Hero(){
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
    return(
        <Fragment>
         
  <section id="hero" className="hero">
    <div className="container position-relative">
      <div className="row gy-5" data-aos="fade-in">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
          <h2>Hire Dedicated Cleaning Professionals to Make your Life Easier</h2>
          <p>We are here to support you with your Cleaning services needs <br />if you are unsure where to go.</p>
          <div className="d-flex justify-content-center justify-content-lg-start">
            <Link to='/contact-us'className="btn-get-started" onClick={scrollToTop}>Book Now</Link>
             </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2">
          <img src="assets/img/mainhero.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
        </div>
      </div>
    </div>

    
  </section>
        </Fragment>
    )
}

export default Hero;