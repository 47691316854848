import React, {useState} from 'react'
import '../components/menu.css'
import {NavLink, Link} from 'react-router-dom';
import {FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";

const Menu = () => {
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
  };

    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
      
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }

  return (
      <header id="header" className="header d-flex align-items-center sticky-top">
        <div className="container align-items-center justify-content-cenetr">
            <div className="row">

                <div className="header__middle__logo">
                <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`}><img src="/assets/img/logo.png" alt="logo" /></NavLink>
                </div>

                <div className="header__middle__menus">
                    <nav className="main-nav " >

                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
                    </>}


                    <ul className={boxClass.join(' ')}>
                    <li  className="menu-item" >
                        <NavLink onClick={toggleClass} activeClassName='is-active' to={`/`}> Home </NavLink> 
                    </li>
                    <li className="menu-item"><NavLink onClick={toggleClass} activeClassName='is-active'  to={`about-us`}> About Us </NavLink> </li>
                    
                    <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="#"> Services <FiChevronDown /> </Link>
                        <ul className={boxClassSubMenu.join(' ')} > 
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`commercial-cleaning-services`}>Commercial Cleaning </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`housekeeping-manpower-services`}>Housekeeping Manpower </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`security-guard-services`}>Security Guard Services</NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`private-security-services`}>Private Security Services</NavLink> </li>
                        </ul>
                    </li>
                       
                   
                    <li className="menu-item"><NavLink onClick={toggleClass}  activeClassName='is-active' to={`contact-us`}> Contact Us</NavLink> </li>
                    </ul>


                    </nav>     
                </div>   
        
            </div>
	    </div>
    </header>
    
  )
}

export default Menu;