import React, { Fragment, useRef } from "react";
import emailjs from "emailjs-com";

function Contactus(){

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_evyszx1",
        "template_t08wqbi",
        form.current,
        "OBeXpfCyQV-DCA79P"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("SUCCESS!");
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
  };

    return(
        <Fragment>
          <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Contact</h2>
          <p>You are always welcome to contact us</p>
        </div>

        <div className="row gx-lg-0 gy-4">

          <div className="col-lg-4">

            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p>H-160, BSI Business Park, Sec - 63, Noida - 201301</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>contact@helperji.in</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+91 7800780095</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-clock flex-shrink-0"></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Sat: 10AM - 07PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8">
            <form ref={form} onSubmit={sendEmail} method="post" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="user_name" className="form-control" id="name" placeholder="Your Name" pattern="[A-Za-z]+(\s[A-Za-z]+){0,2}$" title="Only Character Allowed" required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="user_email" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group mt-3">
              <input type="tel" name="phone" placeholder="Your Mobile No." className="form-control" pattern="[0-9]{10}" title="Only 10 Numbers Allowed" required />
              </div>
              <div className="form-group mt-3">
              <select name="services" style={{width:'100%', padding:'10px', border:"1px solid #ced4da"}} required>
               <option>Select Your Service</option>
               <option value="1">Commercial Cleaning</option>
               <option value="2">Housekeeping Manpower</option>
               <option value="3">Security Guard Services</option>
               <option value="7">Private Security Service</option>
              </select>
               
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
        </Fragment>
    )
}

export default Contactus;